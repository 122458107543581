import React from "react";
import {
  Grid,
  CircularProgress,
  Container,
  Button,
  Icon,
  TextField,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Box,
  Paper,
  Tabs,
  Tab,
  InputLabel,
  FormControl,
  Select,
  Radio,
  useTheme
} from "@material-ui/core";
import { useLang } from "../context/lang";
import { useAccessibility } from "../context/accessibility";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

import { makeStyles } from "@material-ui/core/styles";

import TripTablePanel from "../components/TripTablePanel";
import { AllMovementsTable } from "../containers/AllMovementsTable";
import { Loader } from "../components/Loader";
import { useTrips } from "../context/trips";
import { ITrip, Movement } from "navision-proxy-api/@types/terminal";
import { useViewSettings } from "../context/viewSettings";
import { exportTripsToExcel } from "../utils/export";
import { AlertedMovementsTable } from "../containers/AlertedMovementsTable";
import { useStore } from "../context/store";
import { INAVShipmentLog } from "navision-proxy-api/@types/navTypes";
import ReceiveContainersPopUp from "../widgets/ReceiveMovements/views/ReceiveContainersPopUp";

export const Dashboard = () => {
  const {
    trips,
    loading,
    dateFilter,
    changeDateFilter,
    search,
    changeSearch,
    loadPlans,
    applySearchHighlight,
    saveTrip,
    saveLine
  } = useTrips();

  const { alertedShipments } = useStore();

  console.debug("Dashboard rerenders with", trips);

  const {
    fields,
    visibleSubdepartments,
    changeVisibleSubdepartments,
    visiblePlans,
    changeVisiblePlans,
    toogleVisibleField,
    reverseTripsOrder,
    showAll,
    setShowAll,
    usedPackages,
    filterMode,
    setFilterMode,
    sortTripsBy,
    setSortBy,
    sortOptions,
    showAlertMode,
    setShowAlertMode
  } = useViewSettings();

  const styles = useStyles();
  const [menuEl, setMenuEl] = React.useState<any>({});

  const [sortMenuEl, setSortMenuEl] = React.useState<any>({});
  const INIT_RENDERED_TRIPS = 10;
  const [tripsRendered, setTripsRendered] = React.useState(INIT_RENDERED_TRIPS);
  const { t } = useLang();
  const { topTabIndex, unlockTopAccessibility } = useAccessibility();
  const reverseRef = React.useRef(null);

  /** we use this scrolling tracker only for trips view */
  const trackScrolling = () => {
    if (!showAll) {
      const isBottom = (el: any) =>
        el.getBoundingClientRect().bottom <= window.innerHeight + 1.9;

      const isTop = (el: any) => el.getBoundingClientRect().top > -1;

      const wrappedElement = document.documentElement;

      if (isBottom(wrappedElement) && trips.length > tripsRendered) {
        (document.activeElement as any)?.blur();
        setTripsRendered(tripsRendered + 10);
      } else if (
        isTop(wrappedElement) &&
        tripsRendered != INIT_RENDERED_TRIPS
      ) {
        (document.activeElement as any)?.blur();
        initTripsRendered();
      }
    }
  };

  //lazy loading
  const initTripsRendered = () => {
    const { clientHeight } = document.documentElement;
    if (clientHeight < 700) {
      setTripsRendered(INIT_RENDERED_TRIPS);
    } else if (clientHeight > 700 && clientHeight < 1000) {
      setTripsRendered(INIT_RENDERED_TRIPS + 10);
    } else if (clientHeight > 1000 && clientHeight < 1500) {
      setTripsRendered(INIT_RENDERED_TRIPS + 20);
    } else if (clientHeight > 1500) {
      setTripsRendered(INIT_RENDERED_TRIPS + 40);
    }
  };

  //init tripsRendered for lazy loading
  React.useEffect(() => {
    if (!showAll) {
      initTripsRendered();
    }
  }, [showAll]);

  React.useEffect(() => {
    document.addEventListener("keydown", tabShiftListener);
    return () => {
      document.removeEventListener("keydown", tabShiftListener);
    };
  }, [trips]);

  React.useEffect(() => {
    //KeyboardDatePicker does not accept input props
    document
      ?.getElementById("date-picker-inline")
      ?.setAttribute("tabindex", topTabIndex);
  }, [topTabIndex]);

  React.useEffect(() => {
    if (!showAll) {
      document.addEventListener("scroll", trackScrolling);
    }

    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
  }, [trips, tripsRendered, showAll]);

  const openMenu = (event: any, name: string) => {
    setMenuEl({ ...menuEl, [name]: event.currentTarget });
  };

  const closeMenu = (name: string) => {
    setMenuEl({ ...menuEl, [name]: null });
  };

  /** Used for starting tab navigation from trip rows 
   but after first focus enable also Shift+Tab back to the settings  */
  const tabShiftListener = (e: any) => {
    const isFirstRow = true; //document.activeElement.getAttribute("index") === "0";
    const isActiveBody = document?.activeElement?.tagName === "BODY";
    if (
      e.key === "Tab" &&
      ((e.shiftKey && isFirstRow) ||
        // if user goes up above trips
        (trips.length === 0 && topTabIndex === "-1")) // if there is no
    ) {
      //unlock top if no trips rendered
      unlockTopAccessibility();
    } else if (e.key === "Tab" && e.shiftKey && isActiveBody) {
      //focusing from the init screen
      unlockTopAccessibility();
      (reverseRef.current as any)?.focus();
      e.preventDefault();
    }
  };

  const onTripOpen = React.useMemo(() => applySearchHighlight, []);
  const onSaveTrip = React.useMemo(() => saveTrip, [trips]);
  const onSaveLine = React.useMemo(() => saveLine, [trips]);

  const handleExport = () => {
    exportTripsToExcel(trips, dateFilter, t, fields, usedPackages);
  };

  //tofix - it is rerenders too much

  // const todaysDateFloor = new Date();
  // todaysDateFloor.setHours(0, 0, 0);

  // const editable = dateFilter >= todaysDateFloor;

  //console.log("dashboardd rerender");

  const theme = useTheme();

  return (
    <div id="trips-list" className={styles.root}>
      <Container>
        <Grid container direction="row">
          <Grid item container xs={12}>
            <Grid item md={6} sm={6} xs={12} container>
              <Grid item container md={12} xs="auto" alignItems="center">
                <Box mr={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={dateFilter}
                      onChange={(date: any) => changeDateFilter(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        tabIndex: topTabIndex
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
                <Box display="flex">
                  <Box mr={2}>
                    <Button
                      id="hard-refresh-button"
                      onClick={() => loadPlans({ ignoreCache: true })}
                      size="small"
                      color="primary"
                      tabIndex={topTabIndex}
                    >
                      {t("hardRefresh")}
                      <Icon className="mdi mdi-update" color="primary" />
                    </Button>
                    {/* hidden button to click from the store by id */}
                    <Button
                      style={{ display: "none" }}
                      id="refresh-button"
                      onClick={() => loadPlans({ ignoreCache: false })}
                      size="small"
                      color="primary"
                      tabIndex={topTabIndex}
                    >
                      {t("hardRefresh")}
                      <Icon className="mdi mdi-update" color="primary" />
                    </Button>
                  </Box>
                  <Button
                    onClick={handleExport}
                    size="small"
                    color="primary"
                    tabIndex={topTabIndex}
                  >
                    {t("exportExcel")}
                    <Icon color="primary">save_alt</Icon>
                  </Button>
                  <ReceiveContainersPopUp />
                </Box>
              </Grid>
            </Grid>
            {/* <Grid item sm={2} md={2} lg="auto" xs="auto" /> */}
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
              sm={6}
              container
              justify="flex-end"
            >
              {Object.keys(visiblePlans).map((planId, key) => (
                <Grid
                  key={key}
                  item
                  lg={2}
                  md={2}
                  sm={6}
                  xs={3}
                  container
                  justify="flex-end"
                >
                  <FormControlLabel
                    control={
                      <Button
                        key={key}
                        tabIndex={topTabIndex}
                        onClick={() => {
                          changeVisiblePlans(planId);
                        }}
                      >
                        <Checkbox
                          checked={visiblePlans[planId]}
                          name={planId}
                          tabIndex={-1}
                        />
                      </Button>
                    }
                    label={planId}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justify="space-between"
            className={styles.marginTop}
          >
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                id="search"
                label={t("search")}
                value={search}
                onChange={(event: any) => changeSearch(event.target.value)}
                variant="outlined"
                fullWidth
                color="primary"
                autoComplete=""
                inputProps={{
                  autoComplete: "off",
                  tabIndex: topTabIndex
                }}
              />
            </Grid>
            <Grid item md={4} sm={5} xs={12} container justify="flex-end">
              <Grid item xs={12} container justify="flex-end">
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={e => openMenu(e, "deps")}
                  tabIndex={topTabIndex}
                >
                  {t("showHideDeps")}
                  {Boolean(menuEl.deps) ? (
                    <Icon>expand_less</Icon>
                  ) : (
                    <Icon>expand_more</Icon>
                  )}
                </Button>
                <Menu
                  id="fields-menu"
                  anchorEl={menuEl.deps}
                  open={Boolean(menuEl.deps)}
                  onClose={() => closeMenu("deps")}
                  className={(styles as any)["menu"] as string}
                >
                  {Object.keys(visibleSubdepartments).map((key, i) => (
                    <MenuItem
                      key={i}
                      onClick={() => changeVisibleSubdepartments(key)}
                    >
                      <Checkbox
                        checked={visibleSubdepartments[key]}
                        name={key}
                      />
                      {key}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
              <Grid item xs={12} container justify="flex-end">
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={e => openMenu(e, "fields")}
                  tabIndex={topTabIndex}
                  disabled={showAlertMode}
                >
                  {t("showHide")}
                  {Boolean(menuEl.fields) ? (
                    <Icon>expand_less</Icon>
                  ) : (
                    <Icon>expand_more</Icon>
                  )}
                </Button>
                <Menu
                  id="fields-menu"
                  anchorEl={menuEl.fields}
                  open={Boolean(menuEl.fields)}
                  onClose={() => closeMenu("fields")}
                  className={(styles as any).menu}
                >
                  {fields.map((field, key) => (
                    <MenuItem
                      key={key}
                      onClick={() => toogleVisibleField(field.field || "")}
                    >
                      <Checkbox
                        checked={!field.hidden}
                        onChange={() => {}}
                        name={String(field.title)}
                        key={key}
                      />
                      {field.title}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justify="space-between"
              className={styles.smallReverseColumn}
            >
              <Box display="flex">
                {!showAlertMode && (
                  <>
                    <Tabs
                      value={filterMode}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={(_, value) => {
                        setFilterMode(value);
                      }}
                    >
                      <Tab value="outgoing" label={t("outgoing")} />
                      <Tab value="incoming" label={t("incoming")} />
                    </Tabs>
                    <div
                      className={styles.reverse}
                      onClick={reverseTripsOrder}
                      tabIndex={topTabIndex}
                      ref={reverseRef}
                    >
                      <Icon>swap_vert</Icon>
                      <Typography variant="caption">
                        {t("reverseTrips")}
                      </Typography>
                    </div>
                  </>
                )}
                {showAlertMode && (
                  <Tab
                    value="movedAfterApproval"
                    label={t("movedAfterApproval")}
                  />
                )}
              </Box>
              <Box>
                <Button
                  onClick={e => openMenu(e, "sort")}
                  tabIndex={topTabIndex}
                >
                  {t("sortTripsBy")}
                  {Boolean(menuEl.sort) ? (
                    <Icon>expand_less</Icon>
                  ) : (
                    <Icon>expand_more</Icon>
                  )}
                </Button>

                <Menu
                  id="fields-menu"
                  anchorEl={menuEl.sort}
                  open={Boolean(menuEl.sort)}
                  onClose={() => closeMenu("sort")}
                  className={(styles as any).menu}
                >
                  {sortOptions.map(field => (
                    <MenuItem
                      key={field}
                      onClick={() => {
                        setSortBy(field);
                        closeMenu("sort");
                      }}
                    >
                      <Radio
                        checked={field == sortTripsBy}
                        onChange={() => {}}
                        name={String(field)}
                        key={field}
                      />
                      {t(field)}
                    </MenuItem>
                  ))}
                </Menu>

                {alertedShipments.current.length > 0 && (
                  <Tooltip title={t("showNotifications")}>
                    <Button
                      id="showNotificationsButton"
                      onClick={() => setShowAlertMode(prev => !prev)}
                      tabIndex={topTabIndex}
                    >
                      <Icon style={{ color: theme.palette.warning.main }}>
                        {showAlertMode ? "warning" : "warning_amber"}
                      </Icon>
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title={t("groupByTrips")}>
                  <Button
                    onClick={() => {
                      if (showAll) setShowAll(false);
                      setShowAlertMode(false); //cancel show alert mode
                    }}
                    tabIndex={topTabIndex}
                  >
                    <Icon
                      color={!showAll && !showAlertMode ? "primary" : "inherit"}
                    >
                      view_agenda
                    </Icon>
                  </Button>
                </Tooltip>
                <Tooltip title={t("showAllMovements")}>
                  <Button
                    onClick={() => {
                      if (!showAll) setShowAll(true);
                      setShowAlertMode(false); //cancel show alert mode
                    }}
                    tabIndex={topTabIndex}
                  >
                    <Icon
                      color={showAll && !showAlertMode ? "primary" : "inherit"}
                    >
                      list
                    </Icon>
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} container id="trips-data">
            {loading ? (
              <Grid item xs={12} container justify="center">
                <CircularProgress color="primary" size="10rem" />
              </Grid>
            ) : (
              <DashboardTables
                showAll={showAll}
                showAlertMode={showAlertMode}
                trips={trips}
                tripsRendered={tripsRendered}
                onOpen={onTripOpen}
                saveTrip={onSaveTrip}
                saveLine={onSaveLine}
              />
            )}
          </Grid>

          {/* {tripsRendered < trips.length && !loading && (
            <Grid item xs={12} container justify="center">
              <Typography variant="body1">
                {t("Loading more trips...")}
              </Typography>
            </Grid>
          )} */}
        </Grid>
      </Container>
    </div>
  );
};

interface IDashboardTablesProps {
  //type of view
  showAll: boolean;
  showAlertMode: boolean;
  trips: ITrip[];
  tripsRendered: number;
  onOpen: () => void;
  saveTrip: (trip: ITrip) => void;
  saveLine: (line: Movement) => void;
}

const DashboardTables = React.memo(
  ({
    showAll,
    showAlertMode,
    trips,
    tripsRendered,
    onOpen,
    saveTrip,
    saveLine
  }: IDashboardTablesProps) => {
    const styles = useStyles();

    if (showAlertMode) {
      return (
        <Grid item xs={12}>
          <AlertedMovementsTable
            alertedMovements={trips[0]?.Lines}
            saveLine={saveLine}
          />
        </Grid>
      );
    }

    //all movements view
    if (showAll) {
      return (
        <Grid item xs={12}>
          <AllMovementsTable onOpen={onOpen} />
        </Grid>
      );
    }

    //regular trips view
    return (
      <>
        {trips.slice(0, tripsRendered).map((trip, key) => {
          return (
            <Grid
              key={`${trip.PartialTripNr}_${key}`}
              className={styles.marginTop}
              item
              xs={12}
            >
              <TripTablePanel
                key={`${trip.PartialTripNr}_${key}`}
                index={key}
                //editable={editable}
                trip={trip}
                onOpen={onOpen}
                saveTrip={saveTrip}
              />
            </Grid>
          );
        })}
      </>
    );
  }
);

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiContainer-maxWidthLg": {
      maxWidth: "inherit"
    },
    "& .MuiContainer-root": {
      padding: 0
    },
    marginTop: "6rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "12rem"
    },
    "& .MuiCheckbox-root": {
      [theme.breakpoints.down("xs")]: {
        padding: 6
      }
    },
    "& td:first-child": {
      //change positioning of actions
      "& div button:first-child": {
        order: 2
      }
    }
  },
  marginTop: {
    marginTop: 5
  },
  rightAlign: {
    textAlign: "right"
  },
  smallReverse: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row-reverse"
    }
  },
  smallReverseColumn: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse"
    }
  },
  reverse: {
    color: "rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    width: "8rem"
  }
}));
