import React from "react";
import createPersistedState from "use-persisted-state";
import _ from "lodash";

import { checkShipmentsAlerts, updateMovements } from "../api";
import {
  IPlan,
  ITrip,
  Movement,
  ShipmentAlert
} from "navision-proxy-api/@types/terminal";
import { INAVShipmentLog } from "navision-proxy-api/@types/navTypes";

import { useSnackbar } from "./snackbar";
import moment from "moment";
import { useLang } from "./lang";
import { DEFAULT_DATE_FILTER } from "../config/app";

interface IMovementsConfirmContext {
  selectedMovements: Movement[];
  selectMovements: React.Dispatch<React.SetStateAction<Movement[] | undefined>>;
  updateApproveMovements: (lines: Movement[]) => Promise<Movement[] | null>;
  test: string;
}

const MovementsConfirmContext = React.createContext<IMovementsConfirmContext>(
  {} as IMovementsConfirmContext
);

/** Context for confirming movements via ReceiveMovementsPopUp */
function MovementsConfirmProvider(props: any) {
  const [selectedMovements, setSelectedMovements] = React.useState<Movement[]>(
    []
  );
  const { t } = useLang();

  //const selectMovements = React.useCallback(setSelectedMovements, []);
  const selectMovements = React.useCallback(setSelectedMovements, []);

  /** updates and updates + approve provided lines */
  const updateApproveMovements = async (lines: Movement[] = []) => {
    //merge two lists
    const allLines: Movement[] = [];

    lines.forEach(lineToApprove => {
      const exitingLine = allLines.find(
        l => l.ShipmentNr == lineToApprove.ShipmentNr
      );
      if (!exitingLine) {
        //line isnt in lines to update
        //line already there
        allLines.push({
          ...lineToApprove,
          touchedDate: new Date().toISOString(),
          isNew: true
        });
      } else {
        //line is already in lines to update list
        exitingLine.isNew = true;
        exitingLine.touchedDate = new Date().toISOString();
      }
    });

    await updateMovements(allLines);
    return allLines;

    // we dont want ot rerender the whole thing
    //setShipmentsData(newPlans);
  };

  return (
    <MovementsConfirmContext.Provider
      value={{
        selectedMovements,
        selectMovements,
        updateApproveMovements
        // setAlertedShipments
      }}
      {...props}
    />
  );
}

const useMovementsConfirm = () => React.useContext(MovementsConfirmContext);

export { useMovementsConfirm, MovementsConfirmProvider };
