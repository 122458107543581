import React, { useState, useEffect } from "react";
import createPersistedState from "use-persisted-state";
import { fetchUnits } from "../api";
import { useLang } from "../context/lang";
import { useAuth } from "../context/auth";
import { emitCustomEvent } from "react-custom-events";

import { Typography } from "@material-ui/core";
import {
  IPlan,
  ITrip,
  Movement,
  ClientTerminalAppConfigurations
} from "navision-proxy-api/@types/terminal";
import _ from "lodash";
import { Column } from "material-table";
import { useSnackbar } from "./snackbar";
import moment from "moment";

export interface IVisibilitySetting {
  [key: string]: boolean;
}
const defaultVisibleFields: IVisibilitySetting = {
  SenderName: true,
  ClientClientsNr: false,
  AddresseeName: true,
  Country: false,
  Quantity: true,
  LoadedQty: true,
  Unit: true,
  ActualGate: true,
  SequenceNr: false,
  startTime: true,
  vehicle: true,
  PartialTripNr: false,
  ShipmentNr: false,
  BrandAndNo: false,
  ETA: false,
  PlanInfo: false,
  ShipmentRemark: false
};

const alertModeVisibleFields = [
  "ShipmentNr",
  "SenderName",
  "AddresseeName",
  "oldTrip",
  "newTrip",
  //"PartialTripNr",
  "oldGate",
  "suggestedGate",
  "ActualGate"
];

type ToogleFunc = (keyToToggle: string) => void;

const useSubdepartmentsState = createPersistedState<any>("subdepartments");
const useVisibleFieldsState =
  createPersistedState<IVisibilitySetting>("visibleFields");
const useVisiblePlans =
  createPersistedState<IVisibilitySetting>("visiblePlans");

const useFilterMode = createPersistedState<"incoming" | "outgoing">(
  "tripsViewMode"
);
const useShowAll = createPersistedState<boolean>("showAll");

const useShowAlertMode = createPersistedState<boolean>("showAlertMode");

const useSortTripsBy = createPersistedState<string>("sortTripsBy");

/** fields of app configuration that are needed to be sent on the backend */
export const APP_CONFIG_FIELDS: (keyof ClientTerminalAppConfigurations)[] = [
  "visibleFields",
  "userLanguage",
  "userId",
  "userDepartment",
  // @ts-ignore
  "userDepartmentName", //for old verision
  "departmentAdresses",
  "departmentPlans"
];

/** @depracated - hard to take into account columns that adding after insode material table
 * TODO do freezing in MTableRow
 */
// const DEAFAULT_COLUMN_WIDTH = 100;
// const applyColumnsFreezing = (
//   columns: Column<Movement>[],
//   columnFreezeIndex: number
// ): Column<Movement>[] => {
//   if (columnFreezeIndex == 0) return columns;

//   console.log("freezing");

//   console.log(columns);

//   const extendedColumns = [
//     { width: 50, hidden: false }, //selection column
//     { width: 170, hidden: false }, // actions column
//     ...columns
//   ];

//   //we take into account extended material table columns list
//   //TODO moce this inside material table library
//   //extend default functionality
//   const columnsOffsets = extendedColumns
//     .filter(({ hidden }) => !hidden)
//     .reduce(
//       (acc, column, index) => [
//         ...acc,
//         acc[index] + parseInt(String(column.width))
//       ],
//       [0]
//     );

//   const newColumns: Column<Movement>[] = [];

//   //init index takes into account columns addional columns
//   const initIndex = extendedColumns.length - columns.length;

//   let visibleIndex = initIndex;
//   let visibleFrozenIndex = initIndex;
//   let allIndex = initIndex;

//   const visibleColumnsCount = columns.filter(c => !c.hidden).length;

//   for (let column of columns || []) {
//     const isLastVisibleColumn = visibleIndex + 1 == visibleColumnsCount;
//     newColumns.push(
//       allIndex <= columnFreezeIndex
//         ? {
//             ...column,
//             cellStyle: {
//               ...column.cellStyle,
//               position: "sticky",
//               left: columnsOffsets[visibleIndex],
//               //backgroundColor: "white",
//               zIndex: 10,
//               borderRight:
//                 allIndex === columnFreezeIndex ? "2px solid black" : undefined,
//               overflow: "hidden",
//               boxShadow: isLastVisibleColumn
//                 ? "inset -8px 0px 0px -6px rgba(224, 224, 224, 1)"
//                 : ""
//             },
//             headerStyle: {
//               ...column.headerStyle,
//               position: "sticky",
//               //backgroundColor: "white",
//               left: columnsOffsets[visibleIndex],
//               zIndex: 12,
//               borderRight:
//                 allIndex === columnFreezeIndex ? "2px solid black" : undefined,
//               overflow: "hidden",
//               boxShadow: isLastVisibleColumn
//                 ? "inset -8px 0px 0px -6px rgba(224, 224, 224, 1)"
//                 : ""
//             }
//           }
//         : column
//     );
//     if (!column.hidden) {
//       visibleIndex++;
//       if (allIndex <= columnFreezeIndex) {
//         visibleFrozenIndex++;
//       }
//     }
//     allIndex++;
//   }

//   const frozenTooWide =
//     columnsOffsets[visibleFrozenIndex] + 500 > window.innerWidth;

//   return frozenTooWide ? columns : newColumns;
// };

interface IViewSettingsContext {
  fields: Column<Movement>[];
  initVisibleSubdepartments: (shipmentsData: IPlan[]) => IVisibilitySetting;
  visibleSubdepartments: IVisibilitySetting;
  changeVisibleSubdepartments: ToogleFunc;
  visiblePlans: IVisibilitySetting;
  setVisiblePlans: (newVisiblePlans: IVisibilitySetting) => void;
  setUsedPackages: React.Dispatch<React.SetStateAction<string[]>>;
  usedPackages: string[];
  changeVisiblePlans: ToogleFunc;
  toogleVisibleField: ToogleFunc;
  reverseTripsOrder: () => void;
  isTripsReversed: boolean;
  showAll: boolean;
  setShowAll: (value: boolean) => void;
  /** quick fix: we want to save packages to be able to re-init them with new lang or updated hidden settings */
  lastLoadedPackages: React.MutableRefObject<any[]>;
  initPackages: (packages: any[], usedPackages: any[]) => void;
  filterMode: "incoming" | "outgoing";
  setFilterMode: React.Dispatch<React.SetStateAction<"incoming" | "outgoing">>;
  showAlertMode: boolean;
  setShowAlertMode: React.Dispatch<React.SetStateAction<boolean>>;
  sortTripsBy: string;
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
  sortOptions: string[];
}

const ViewSettings = React.createContext<IViewSettingsContext>(
  {} as IViewSettingsContext
);

function ViewSettingsProvider(props: {
  children: React.ReactElement;
}): React.ReactElement {
  /** State */

  const [isTripsReversed, setIsTripsReversed] = useState(false);
  const [showAlertMode, setShowAlertMode] = useShowAlertMode(false);

  const [visiblePlans, setVisiblePlans] = useVisiblePlans({});

  const [filterMode, setFilterMode] = useFilterMode("outgoing");

  const { t, lang } = useLang();
  const [showAll, setShowAll] = useShowAll(false);

  /** using defaults here sometimes lags for some reason, so I am using defaults in the isFieldHidden */
  const [visibleFields, setVisibleFields] = useVisibleFieldsState({});
  const [visibleSubdepartments, setVisibleSubdepartments] =
    useSubdepartmentsState({});

  const [fields, setFields] = useState<Column<Movement>[]>([]);
  const [units, setUnits] = useState({});
  const [usedPackages, setUsedPackages] = useState<string[]>([]);

  const sortOptions = [
    "PartialTripNr",
    "Vehicle",
    "Trailer",
    "Gates",
    "StartTime"
  ];

  const [sortTripsBy, setSortBy] = useSortTripsBy(sortOptions[0]);

  const { isAuthenticated } = useAuth();

  const { openSnackbar } = useSnackbar();
  /** Effects */

  const initPackages = (packages: any[], usedPackages: any[]) => {
    setFields(prev => {
      let newFields = [
        ...prev.filter(({ field }) => {
          return !field?.includes("Packages.");
        }),
        ...packages?.map((p: any) => ({
          title: t(p),
          field: `Packages.${p}`,
          hidden: isFieldHidden(`Packages.${p}`), //later we set used packages as visible
          width: 70,
          editable: "always",
          sorting: false,
          emptyValue: "0",
          type: "numeric"
        }))
      ] as Column<Movement>[];

      if (showAlertMode) {
        newFields = newFields
          .filter(({ field }) => alertModeVisibleFields.includes(field || ""))
          .map(field => ({ ...field, hidden: false }));
      }

      return newFields;
    });

    //set used packages as visible
    setVisibleFields(prev => {
      const newVisibleFields = { ...prev };
      usedPackages.forEach((p: any) => {
        newVisibleFields[`Packages.${p}`] = true;
      });
      return newVisibleFields;
    });
  };

  // /** since packeges are added later when the data is loaded,
  //  * we need to triger this manually when visibility is changed */
  // const reloadFieldsVisability = () => {
  //   setFields(prev => {
  //     return [
  //       ...prev.map(field => ({
  //         ...field,
  //         hidden: isFieldHidden(field?.field)
  //       }))
  //     ];
  //   });
  // };

  const initVisibleSubdepartments = (shipmentsData: IPlan[]) => {
    //logic should be moved to backend
    const visibleSD = { ...visibleSubdepartments };
    const subdepartments: any = _.uniq(
      shipmentsData
        .reduce((acc, { Trips }) => [...acc, ...Trips], [] as ITrip[])
        .map(({ Subdepartment }) => Subdepartment)
    );

    //init new
    if (subdepartments.length > 0) {
      subdepartments.forEach((sd: any) => {
        if (visibleSD[sd] === undefined) {
          visibleSD[sd] = true;
        }
      });
      //clear old
      Object.keys(visibleSD).forEach((sd: any) => {
        if (!subdepartments.includes(sd)) {
          delete visibleSD[sd];
        }
      });
      if (!_.isEqual(visibleSD, visibleSubdepartments))
        setVisibleSubdepartments({ ...visibleSD });
    }

    return visibleSD;
  };

  //init fields
  const lastLoadedPackages = React.useRef<any[]>([]);
  useEffect(() => {
    const initFields = async () => {
      moment.locale(lang);
      console.debug("running init fields");
      const showAllModeFields = showAll
        ? [
            {
              title: t("vehicle"),
              field: "Vehicle",
              hidden: isFieldHidden("vehicle"),
              width: 100,
              editable: "never",
              sorting: false
            },
            {
              title: t("startTime"),
              field: "StartTime",
              hidden: isFieldHidden("startTime"),
              width: 100,
              editable: "never",
              sorting: false
              // render: (value: any) =>
              //   value["StartTime"]
              //     .split(":")
              //     .map((el: any, i: any) => (i === 0 ? addTime(2, el) : el))
              //     .join(":")
            }
          ]
        : [];
      console.debug("init fields", showAlertMode);
      /** fields for notifications window with movements that were errored */
      const showAlertMovementsModeFields = showAlertMode
        ? [
            {
              title: t("oldTrip"),
              field: "oldTrip",
              hidden: isFieldHidden("oldTrip"),
              width: 120,
              editable: "never",
              sorting: true
            },
            {
              title: t("newTrip"),
              field: "newTrip",
              hidden: isFieldHidden("newTrip"),
              width: 120,
              editable: "never",
              sorting: true
            },
            {
              title: t("oldGate"),
              field: "oldGate",
              hidden: isFieldHidden("newTrip"),
              width: 120,
              editable: "never",
              sorting: true
            },
            {
              title: t("suggestedGate"),
              field: "suggestedGate",
              hidden: isFieldHidden("suggestedGate"),
              width: 150,
              editable: "never",
              sorting: true
            }
          ]
        : [];

      let basicFields = [
        {
          title: t("partialTripNr"),
          field: "PartialTripNr",
          hidden: isFieldHidden("PartialTripNr"),
          width: 100,
          editable: "never",
          sorting: false
        },
        {
          title: t("Shipment Nr."),
          field: "ShipmentNr",
          hidden: isFieldHidden("ShipmentNr"),
          width: 140,
          editable: "never",
          sorting: false,
          //it renders additional text to the one defined in TripTable/TripTableCell
          render: (props: any) => {
            return (
              <div>
                <div>
                  <span className="search-highlightable">
                    {props.ShipmentNr}
                  </span>
                </div>

                <Typography variant="caption">
                  ({t("Movement")}:
                  {props.MovementNr ? parseInt(props.MovementNr) / 10000 : ""},
                  {t("Line")}:
                  {props.LineNr && props.LineNr[0]
                    ? parseInt(props.LineNr[0]) / 10000
                    : ""}
                  )
                </Typography>
              </div>
            );
          }
        },
        {
          title: t("sendername"),
          field: "SenderName",
          hidden: isFieldHidden("SenderName"),
          width: 200,
          editable: "never",
          sorting: false,
          //it renders additional text to the one defined in TripTable/TripTableCell
          render: (props: any) => {
            return props.RawMovement.LoadAddressName != props.SenderName ? (
              <div>
                <div>
                  <span className="search-highlightable">
                    {props.SenderName}
                  </span>
                </div>
                <Typography variant="caption">
                  ({props.RawMovement.LoadAddressName})
                </Typography>
              </div>
            ) : (
              <span className="search-highlightable">{props.SenderName}</span>
            );
          }
        },
        {
          title: t("clientsClient"),
          field: "ClientClientsNr",
          hidden: isFieldHidden("ClientClientsNr"),
          width: 163,
          editable: "never",
          sorting: false
        },
        {
          title: t("adreseeName"),
          field: "AddresseeName",
          hidden: isFieldHidden("AddresseeName"),
          width: 200,
          editable: "never",
          sorting: false,

          render: (props: any) => {
            return props.RawMovement.UnloadAddressName !=
              props.AddresseeName ? (
              <div>
                <div>
                  <span className="search-highlightable">
                    {props.AddresseeName}
                  </span>
                </div>
                <Typography variant="caption">
                  ({props.RawMovement.UnloadAddressName})
                </Typography>
              </div>
            ) : (
              <span className="search-highlightable">
                {props.AddresseeName}
              </span>
            );
          }
        },
        {
          title: t("BrandAndNo"),
          field: "BrandAndNo",
          hidden: isFieldHidden("BrandAndNo"),
          width: 150,
          editable: "never",
          sorting: false
        },
        {
          title: t("ETA"),
          field: "ETA",
          hidden: isFieldHidden("ETA"),
          ignored: filterMode == "outgoing",
          width: 150,
          editable: "never",
          sorting: false,
          hideOriginal: true,
          render: (props: any) => {
            return props.ETA ? (
              <span>{moment(props.ETA).format("HH:mm (DD-MM)")}</span>
            ) : undefined;
          }
        },
        {
          title: t("PlanInfo"),
          field: "PlanInfo",
          hidden: isFieldHidden("PlanInfo"),
          width: 150,
          editable: "never",
          sorting: false
        },
        {
          title: t("ShipmentRemark"),
          field: "ShipmentRemark",
          hidden: isFieldHidden("ShipmentRemark"),
          width: 150,
          editable: "never",
          sorting: false
        },
        {
          title: t("adresseCountry"),
          field: "Country",
          hidden: isFieldHidden("Country"),
          width: 100,
          editable: "never",
          sorting: false
        },
        {
          title: t("qty"),
          field: "Quantity",
          hidden: isFieldHidden("Quantity"),
          width: 50,
          editable: "never",
          sorting: false,
          emptyValue: "0",
          type: "numeric"
        },
        {
          title: t("loadedQty"),
          field: "LoadedQty",
          hidden: isFieldHidden("LoadedQty"),
          width: 100,
          editable: "always",
          sorting: false,
          emptyValue: "0",
          type: "numeric"
        },
        {
          title: t("unit"),
          field: "Unit",
          lookup: units,
          width: 120,
          hidden: isFieldHidden("Unit"),
          editable: "always",
          sorting: false
        },
        ...showAlertMovementsModeFields,
        {
          title: t("actualGate"),
          field: "ActualGate",
          hidden: isFieldHidden("ActualGate"),
          width: 100,
          editable: "always",
          sorting: false,
          emptyValue: "0"
        },
        {
          title: "SequenceNr",
          field: "SequenceNr",
          hidden: isFieldHidden("SequenceNr"),
          width: 100,
          editable: "always",
          sorting: false,
          emptyValue: "0"
        }
      ];

      //first init of visible fields that will be stored in a local storage
      // if (Object.keys(visibleFields).length === 0 && packages.length > 0) {
      //   const visibleFieldsObj = {};
      //   const isBasicFieldHidden = key => !["Country"].includes(key);
      //   packages.forEach(p => {
      //     visibleFieldsObj[`Packages.${p["ID"]}`] = visiblePackages.includes(
      //       p["Key"]
      //     );
      //   });

      //   basicFields.forEach(({ field }) => {
      //     visibleFieldsObj[field] = isBasicFieldHidden(field);
      //   });

      //   setVisibleFields(visibleFieldsObj);
      // }

      /**
       * We add packages fields later when loading plans
       */

      setFields(prev => {
        const newFields = [
          ...showAllModeFields,
          ...basicFields
          //...showAlertMovementsModeFields
        ].filter(({ ignored }: any) => !ignored);

        let allFields = [
          ...newFields,
          ...prev
            .filter(({ field }) => field?.includes("Packages.")) //preserve previous packages state
            .map(f => ({ ...f, hidden: isFieldHidden(f.field) })) //but check if it is hidden
        ] as Column<Movement>[];

        if (showAlertMode) {
          allFields = allFields
            .filter(({ field }) => alertModeVisibleFields.includes(field || ""))
            .map(field => ({ ...field, hidden: false }));
        }

        console.debug("inited fields", allFields);

        return allFields as Column<Movement>[];
      });
    };

    initFields();
    // if (lastLoadedPackages.current.length)
    //   initPackages(lastLoadedPackages.current);
  }, [
    lang,
    units,
    // packages,
    visibleFields,
    showAll,
    showAlertMode,
    filterMode
  ]);

  const isFieldHidden = (key: string | undefined) => {
    let isFieldVisible;
    if (key) {
      if (visibleFields[key] !== undefined) {
        isFieldVisible = visibleFields[key];
      } else {
        isFieldVisible = defaultVisibleFields[key] || false;
      }

      // if (!isFieldVisible) {
      //   //recheck defaults because sometimes it not being load up in a local storage for some reason...
      //   isFieldVisible = key ? defaultVisibleFields[key] : false;
      // }

      if (isFieldVisible === true) {
        return false;
      } else if (isFieldVisible === false) {
        return true;
      } else if (isFieldVisible === undefined && key !== undefined) {
        //field is not initialized
        setVisibleFields({ ...visibleFields, [key]: false });
      }
    }
    return !isFieldVisible;
  };

  //fetch units
  useEffect(() => {
    const loadUnits = async () => {
      try {
        const data = await fetchUnits();

        const units: any = {};

        data.forEach((u: any) => {
          units[u.Key] = u.Value.find((v: any) => v.lang === lang)?.val;
        });

        setUnits(units);
      } catch (err) {
        console.error(err);
        openSnackbar(`Error loading units: ${err}`, "error");
      }
    };
    if (isAuthenticated) {
      loadUnits();
    }
    //loadPackages();
  }, [isAuthenticated]);

  // const loadPackages = async () => {
  //   try {
  //     const packages = await fetchPackages();
  //     setPackages(packages);
  //   } catch (err) {
  //     console.error(err);
  //     setError(`Error loading profiles: ${err}`);
  //   }
  // };

  /** ACTIONS */
  const reverseTripsOrder = () => {
    setIsTripsReversed(!isTripsReversed);
  };

  const changeVisiblePlans = (planId: any) => {
    setVisiblePlans({ ...visiblePlans, [planId]: !visiblePlans[planId] });
    emitCustomEvent("userSetupChange");
  };

  const changeVisibleSubdepartments = (key: any) => {
    setVisibleSubdepartments({
      ...visibleSubdepartments,
      [key]: !visibleSubdepartments[key]
    });
  };

  const toogleVisibleField = (fieldId: any) => {
    setVisibleFields({ ...visibleFields, [fieldId]: !visibleFields[fieldId] });
    emitCustomEvent("userSetupChange");
  };

  return (
    <ViewSettings.Provider
      value={{
        showAlertMode,
        setShowAlertMode,
        setUsedPackages,
        usedPackages,
        setVisiblePlans,
        lastLoadedPackages,
        initPackages,
        initVisibleSubdepartments,
        fields: fields,
        visibleSubdepartments: visibleSubdepartments,
        changeVisibleSubdepartments: changeVisibleSubdepartments,
        visiblePlans: visiblePlans,
        changeVisiblePlans: changeVisiblePlans,
        toogleVisibleField: toogleVisibleField,
        reverseTripsOrder: reverseTripsOrder,
        isTripsReversed: isTripsReversed,
        showAll: showAll,
        setShowAll: setShowAll,
        filterMode,
        setFilterMode,
        sortTripsBy,
        setSortBy,
        sortOptions
      }}
      {...props}
    />
  );
}

const useViewSettings = () => React.useContext(ViewSettings);

export { useViewSettings, ViewSettingsProvider };
