import ExcelJS, { Fill } from "exceljs";
import moment from "moment";
import {
  IMovement,
  IPlan,
  ITrip,
  Movement
} from "navision-proxy-api/@types/terminal";
import { Column } from "material-table";

const greenColor = "c3e0c4";
const greyColor = "a6a6a6";
const greenFill: Fill = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: greenColor }
};

const getAmountsForLines = (lines: IMovement[]) => {
  //amount
  const amountsMap: { [unit: string]: number } = {};
  const actualAmountMap: { [unit: string]: number } = {};

  lines.forEach(line => {
    line.Unit.forEach((unit, i) => {
      amountsMap[unit] =
        (amountsMap[unit] || 0) + parseInt(line.Quantity[i] as any);
      actualAmountMap[unit] =
        (actualAmountMap[unit] || 0) + parseInt(line.LoadedQty[i]);
    });
  });

  const amountsString = Object.entries(amountsMap)
    .map(([k, v]) => `${v} ${k}`)
    .join(", ");

  const actualAmountsString = Object.entries(actualAmountMap)
    .map(([k, v]) => `${v} ${k}`)
    .join(", ");

  return [amountsString, actualAmountsString];
};

const parseValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.join(",");
  } else {
    return value;
  }
};

export const exportTripsToExcel = async (
  trips: ITrip[],
  date: Date,
  t: (keyToTranslate: string) => string,
  fields: Column<Movement>[],
  usedPackages: string[]
) => {
  const lines: IMovement[] = trips.reduce<IMovement[]>(
    (acc, t) => [...acc, ...t.Lines],
    []
  );

  const workbook = new ExcelJS.Workbook();
  workbook.creator = "Alex Andersen";
  workbook.lastModifiedBy = "Alex Andersen";
  workbook.created = new Date();

  const name = `${t("Trips")} ${moment(date).format("DD-MM-yyyy").toString()}`;
  const worksheet = workbook.addWorksheet(name);
  worksheet.properties.defaultColWidth = 7;

  worksheet.views = [{ state: "frozen", xSplit: 0, ySplit: 5 }];

  /** temp solution */
  const allowedLineAppFields = [
    "SenderName",
    "ClientClientsNr",
    "AddresseeName",
    "Quantity",
    "LoadedQty",
    "Unit",
    "ActualGate"
  ];
  //setup columns width
  worksheet.columns = [
    { width: 13 }, //trip nr
    { width: 8 }, //vehicle
    { width: 7 }, //start time
    { width: 27 }, //sender
    { width: 16 }, //CC
    { width: 27 } //adreseee
    //quantities, packages
  ];

  //add date
  worksheet.addRow([
    t("departureDate"),
    "",
    moment(date).format("DD-MM-yyyy").toString()
  ]);
  //empty divider
  worksheet.addRow([]);

  const [amountString, actualAmountString] = getAmountsForLines(lines);
  worksheet.addRow([t("qty"), "", amountString]).getCell(1).font = {
    bold: true
  };
  worksheet.addRow([t("loadedQty"), "", actualAmountString]);

  /** init fields */
  // console.log(fields);
  //trip fields
  const tripFields = [
    { field: "PartialTripNr", title: t("partialTripNr") },
    { field: "Vehicle", title: t("vehicle") },
    { field: "StartTime", title: "Start" }
  ];

  //app line fields
  const lineAppFields = fields.filter(
    ({ field, hidden }) =>
      //!hidden && TODO check this
      field &&
      allowedLineAppFields.includes(field) &&
      !field?.includes("Packages.")
  );

  //packages fields
  const packagesFields = fields.filter(
    ({ field }) =>
      field?.includes("Packages.") &&
      usedPackages?.includes(field?.split(".")[1])
  );

  const allHeaderFields = [...tripFields, ...lineAppFields, ...packagesFields];
  const allLineFields = [...lineAppFields, ...packagesFields];

  const headerRow = worksheet.addRow(allHeaderFields.map(({ title }) => title));
  headerRow.fill = greenFill;
  headerRow.border = {
    top: { style: "thin", color: { argb: greyColor } },
    left: { style: "thin", color: { argb: greyColor } },
    bottom: { style: "thin", color: { argb: greyColor } }
  };
  headerRow.height = 40;
  headerRow.eachCell(cell => {
    cell.alignment = { vertical: "middle", horizontal: "left", wrapText: true };
  });

  /** Add data */
  trips.forEach(trip => {
    const packagesSum: { [packageName: string]: number } = {};
    trip.Lines.forEach(line => {
      const tripValues = tripFields.map<any>(({ field }) => {
        return trip[field as keyof ITrip];
      });

      const allValues = tripValues.concat(
        allLineFields.map<any>(({ field }) => {
          if (field?.includes("Packages.")) {
            const packageKey = field.split(".")[1];
            const packageValue = parseValue(line.Packages[packageKey]);
            if (packageValue) {
              packagesSum[packageKey] =
                (packagesSum[packageKey] || 0) + parseInt(packageValue);
            }
            return packageValue;
          } else {
            return parseValue(line[field as keyof Movement]);
          }
        })
      );
      worksheet
        .addRow(allValues)
        .eachCell(cell => (cell.style.alignment = { wrapText: true }));
    });
    //add total
    const [amount, actualAmount] = getAmountsForLines(trip.Lines);
    // const amountString =
    //   amount == actualAmount ? amount : amount + " " + actualAmount;
    worksheet
      .addRow([
        trip.PartialTripNr,
        "",
        "",
        "",
        "",
        "",
        amount,
        "",
        "",
        "",
        ...packagesFields.map(p =>
          p.field ? String(packagesSum[p.field.split(".")[1]] || "") : ""
        )
      ])
      .eachCell(cell => {
        cell.font = { bold: true };
      });

    //empty green divider
    const divider = worksheet.addRow(allHeaderFields.map(() => ""));
    divider.fill = greenFill;
    divider.border = {
      top: { style: "thin", color: { argb: greyColor } },
      bottom: { style: "thin", color: { argb: greyColor } }
    };
  });

  // worksheet.eachRow(row =>
  //   row.eachCell(
  //     cell =>
  //       (cell.border = {
  //         top: { style: "thin", color: { argb: greyColor } },
  //         bottom: { style: "thin", color: { argb: greyColor } },
  //         left: { style: "thin", color: { argb: greyColor } },
  //         right: { style: "thin", color: { argb: greyColor } }
  //       })
  //   )
  // );

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer]);

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = url;
  a.download = name + ".xlsx";
  a.click();
  window.URL.revokeObjectURL(url);
};
