import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useCustomEventListener } from "react-custom-events";
import _, { filter } from "lodash";

import {
  login as loginApi,
  logout as logoutApi,
  saveUserApi,
  fetchUserApi
} from "../api";
import { USER_SAVE_INTERVAL } from "../config/app";
import { Loader } from "../components/Loader";
import {
  TerminalAppConfigurations,
  ClientTerminalAppConfigurations,
  TerminalUser
} from "navision-proxy-api/@types/terminal";
import { APP_CONFIG_FIELDS } from "./viewSettings";
import { isObject } from "../utils/trip";

interface IAuthContext {
  isAuthenticated: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  // visiblePackages: string[];
  userId: string | null;
  userLanguage: string;
  userDepartment: string | null;
  userDepartmentName: string | null;
}

const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  isLoading: false,
  isError: false,
  errorMessage: "",
  login: async () => false,
  logout: async () => {},
  // visiblePackages: [],
  userId: null,
  userLanguage: "en",
  userDepartment: null,
  userDepartmentName: null
});

function AuthProvider(
  props: {
    children: React.ReactElement;
  } & RouteComponentProps
): React.ReactElement {
  const [authState, setAuthState] = React.useState(
    checkAuth() ? "done" : "init"
  );

  const isAuthenticated = authState === "done";
  const isLoading = authState === "loading";
  const isError = authState === "error";

  const [userLoading, setUserLoading] = React.useState(true);

  const [errorMessage, setErrorMessage] = React.useState("");

  // const [visiblePackages, setVisiblePackages] = React.useState(
  //   (localStorage.getItem("visiblePackages") || "").split(",")
  // );

  const [userId, setUserId] = React.useState(
    localStorage.getItem("userId") || null
  );

  const [userDepartment, setUserDepartment] = React.useState(
    localStorage.getItem("userDepartment") || null
  );

  const [userLanguage, setUserLanguage] = React.useState(
    localStorage.getItem("userLanguage") || "en"
  );

  const [userDepartmentName, setUserDepartmentName] = React.useState(
    localStorage.getItem("userDepartmentName") || null
  );

  React.useEffect(() => {
    fetchUserSetup();
  }, [authState]);

  const login = async (username: string, password: string) => {
    //New state userDepartmentName That is getting data from the localstorage

    try {
      setAuthState("loading");
      const user: TerminalUser = await loginApi(username, password);
      unpackAppConfigurations(user.appConfigurations);
      // setVisiblePackages(
      //   (localStorage.getItem("visiblePackages") || "").split(",")
      // );
      setUserId(localStorage.getItem("userId"));
      setUserLanguage(localStorage.getItem("userLanguage") || "en");
      setUserDepartment(localStorage.getItem("userDepartment"));
      setUserDepartmentName(localStorage.getItem("userDepartmentName"));
      setAuthState("done");
      props.history.push("/");
      return true;
    } catch (error: any) {
      console.log("error");
      console.log(error);
      setAuthState("error");
      setErrorMessage(error?.message);
      return false;
    }
  }; // make a login request

  const logout = async () => {
    await saveUserSetup();
    localStorage.clear();
    setAuthState("init");
  };

  const saveUserSetup = async () => {
    const appConfigurations = { ...localStorage };

    //filter only fields that should be saved in the API
    const filteredAppConfigurations = _.pickBy(
      appConfigurations,
      (value, key) =>
        APP_CONFIG_FIELDS.includes(key as keyof ClientTerminalAppConfigurations)
    ) as ClientTerminalAppConfigurations;
    //parse string localstorage values to JSON
    const parsedAppConfigurations = _.mapValues(
      filteredAppConfigurations,
      (value: any) => {
        try {
          return JSON.parse(value);
        } catch (e) {
          return value;
        }
      }
    ) as ClientTerminalAppConfigurations;

    //filter packages from visible fields since we dont want to save them
    //because they are changed every time the data is loaded
    const packageFilteredAppConfigurations = {
      ...parsedAppConfigurations,
      visibleFields: _.pickBy(
        parsedAppConfigurations.visibleFields,
        (value, key) => !key.includes("Packages.")
      )
    };

    await saveUserApi(
      appConfigurations.username,
      packageFilteredAppConfigurations
    );
  };

  const debouncedSaveUserSetup = _.debounce(saveUserSetup, USER_SAVE_INTERVAL, {
    leading: false,
    trailing: true
  });

  useCustomEventListener("userSetupChange", data => {
    console.debug("User setup change event received");

    debouncedSaveUserSetup();
  });

  const unpackAppConfigurations = (
    appConfigurations: ClientTerminalAppConfigurations
  ) => {
    console.log("Unpacking app configurations");
    for (var appConf in appConfigurations) {
      // @ts-ignore: appConf always in userSetup.appConfigurations
      const configuration = appConfigurations[appConf];
      localStorage.setItem(
        appConf,
        isObject(configuration) ? JSON.stringify(configuration) : configuration
      );
    }
  };

  const fetchUserSetup = async () => {
    try {
      const username = localStorage.getItem("username");
      if (username) {
        const userSetup: TerminalUser = await fetchUserApi(username);

        unpackAppConfigurations(userSetup.appConfigurations);
      }
    } catch (err: any) {
      console.error("Error fetching user setup");
      console.error(err);
    }
    setUserLoading(false);
  };

  //Add the userDepartmentName to this object
  return (
    <Loader loading={userLoading}>
      <AuthContext.Provider
        value={{
          isAuthenticated,
          isLoading,
          isError,
          errorMessage,
          login,
          logout,
          // visiblePackages,
          userId,
          userLanguage,
          userDepartment,
          userDepartmentName
        }}
        {...props}
      />
    </Loader>
  );
}

const checkAuth = () => !!localStorage.getItem("userId");

const useAuth = () => React.useContext(AuthContext);

const AuthProviderRouter = withRouter(AuthProvider);

export { useAuth, AuthProviderRouter as AuthProvider };
