/** This is imporoved cleaned up version of TripTable */
import React from "react";
import Box from "@material-ui/core/Box";
import { Column } from "material-table";
import MaterialTable from "material-table";
import { useMovementsConfirm } from "../../../context/movementsConfirm";
import { useLang } from "../../../context/lang";
import { Movement } from "navision-proxy-api/@types/terminal";
import { bulkEditPatch } from "../../../utils/materialTableExtentions";
import { useSnackbar } from "../../../context/snackbar";
import { useViewSettings } from "../../../context/viewSettings";
import { useTripsApiActions } from "../../../hooks/useTripsApiActions";

// const getMainColumns = (t: any): Column<Movement>[] => {
//   return [
//     {
//       title: t("ShipmentNr"),
//       field: "ShipmentNr",
//       hidden: true,
//       editable: "never",
//       sorting: false
//     },
//     {
//       title: t("SenderName"),
//       field: "SenderName",
//       hidden: false,
//       editable: "never",
//       sorting: false
//     },
//     {
//       title: t("AddresseeName"),
//       field: "AddresseeName",
//       hidden: false,
//       editable: "never",
//       sorting: false
//     },
//     {
//       title: t("LoadedQty"),
//       field: "LoadedQty",
//       hidden: false,
//       editable: "never",
//       sorting: false,
//       type: "numeric"
//     },
//     {
//       title: "Unit",
//       field: "Unit",
//       hidden: false,
//       editable: "never",
//       sorting: false
//     },
//     {
//       title: t("T6 CC"),
//       field: "Packages.T6 CC",
//       hidden: false,
//       editable: "always",
//       type: "numeric"
//     },
//     {
//       title: t("HYLDER"),
//       field: "Packages.HYLDER",
//       hidden: false,
//       editable: "always",
//       type: "numeric"
//     },
//     {
//       title: t("FORL&dot;"),
//       field: "Packages.FORL&dot;",
//       hidden: false,
//       width: 70,
//       editable: "always",
//       sorting: false,
//       emptyValue: "0",
//       type: "numeric"
//     }
//   ];
// };

const ignoredFields = [
  "PartialTripNr",
  "Vehicle",
  "Driver",
  "Trailer",
  "Quantity",
  "ActualGate"
];

export const MovementsTable = ({
  submitRef: submitMainRef,
  afterSubmitHook
}: {
  submitRef: React.MutableRefObject<any>;
  afterSubmitHook?: () => void;
}) => {
  const [mainLoading, setMainLoading] = React.useState(false);
  const { t } = useLang();
  //   const mainColumns = React.useMemo(() => getMainColumns(t), []);
  const { selectedMovements, selectMovements, updateApproveMovements } =
    useMovementsConfirm();
  const { openSnackbar } = useSnackbar();
  const { fields } = useViewSettings();

  const filteredFields = fields
    .map(f => ({ ...f, selectOnFocus: true }))
    .filter(({ field }: any) => !ignoredFields?.includes(field));

  //MATERIAL TABLE BULK EDIT PATCH
  //TODO: move it inside wrapper component or/and inside material table
  //hack for controlling table edit mode
  const startEditMainRef = React.useRef<any>(null);

  const handleEditMain = async (
    editedData: Record<
      number,
      {
        oldData: Movement;
        newData: Movement;
      }
    >
  ) => {
    setMainLoading(true);
    try {
      //display changes in the table
      const newSelection = [...selectedMovements];
      Object.keys(editedData).forEach((key: string) => {
        newSelection[parseInt(key)] = editedData[parseInt(key)].newData;
      });
      selectMovements(newSelection);
      //do api call
      const result = await updateApproveMovements(newSelection);
      if (result && afterSubmitHook) {
        afterSubmitHook();
      }

      if (!result) {
        //if failed continue editing
        if (startEditMainRef.current) {
          startEditMainRef.current();
        }
      }
    } catch (e: any) {
      console.error(e);
      openSnackbar(`Update movements error ${e?.message}`, "error");
    }
    setMainLoading(false);
  };

  const editMainPatch = bulkEditPatch<Movement>(
    submitMainRef,
    startEditMainRef,
    handleEditMain
  );

  React.useEffect(() => {
    if (startEditMainRef.current) {
      startEditMainRef.current();
    }
    //   if (startEditTotalRef.current) {
    //     startEditTotalRef.current();
    //   }
  }, []);
  //END MATERIAL TABLE BULK EDIT PATCH
  //TODO: move it to wrapper componenet
  return (
    <Box width={"100%"} py={4} height={{ xs: "50vh", md: "40vh" }}>
      <MaterialTable
        isLoading={mainLoading}
        columns={filteredFields}
        data={selectedMovements}
        editable={editMainPatch.editableActions}
        options={{
          selection: false,
          toolbar: true, //for getting bulk action, later hide in components overwrite
          paging: false,
          minBodyHeight: "200px",
          maxBodyHeight: "40vh",
          ...editMainPatch.editableOptions
        }}
        components={{
          ...editMainPatch.editComponents
        }}
      />
    </Box>
  );
};
