import React from "react";
import { bulkEditPatch } from "../../../utils/materialTableExtentions";
import { useMovementsConfirm } from "../../../context/movementsConfirm";
import { Movement } from "navision-proxy-api/@types/terminal";
import { useLang } from "../../../context/lang";
import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import { registerContainer } from "../../../api";
import { useSnackbar } from "../../../context/snackbar";
import { useViewSettings } from "../../../context/viewSettings";

export type PackageContainerInfo = {
  packageName: string;
  packageKey: string;
  in: number;
  out: number;
};

const AddNewRow = ({
  onAdd,
  options
}: {
  onAdd: (option: string) => void;
  options: string[];
}) => {
  const { t } = useLang();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option: string) => {
    onAdd(option);
    handleClose();
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Icon>add</Icon>}
        onClick={handleButtonClick}
      >
        Add New Row
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleOptionClick(option)}>
            {t(option)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const getTotalColumns = (
  t: any,
  onAdd: (option: string) => void,
  options: string[],
  hideIn?: boolean
): Column<PackageContainerInfo>[] => {
  const columns: any = [
    {
      title: <AddNewRow onAdd={onAdd} options={options} />,
      field: "packageName",
      hidden: false,
      sorting: false,
      editable: "never",
      selectOnFocus: true
    },
    {
      title: t("out"),
      field: "out",
      hidden: false,
      editable: "always",
      type: "numeric",
      selectOnFocus: true
    }
  ];

  if (!hideIn) {
    columns.push({
      title: t("in"),
      field: "in",
      hidden: false,
      editable: "never",
      type: "numeric",
      selectOnFocus: true
    });
  }

  return columns;
};

const getInitTotalData = (
  movements: Movement[],
  fields: Column<Movement>[],
  t: any
): PackageContainerInfo[] => {
  const totalIn = fields
    .filter(
      ({ field, hidden }) =>
        field && field.includes("Package") && hidden == false
    )
    .reduce<{ [packageName: string]: number }>(
      (acc, { field }: any) => ({
        ...acc,
        [field.replace("Packages.", "")]: 0
      }),
      {}
    );

  movements?.forEach(movement => {
    Object.keys(totalIn).forEach((key: any) => {
      totalIn[key] += parseInt(String(movement.Packages[key] || "0"));
    });

    // totalIn["T6 CC"] += parseInt(String(movement.Packages["T6 CC"] || "0"));
    // totalIn["HYLDER"] += parseInt(String(movement.Packages["HYLDER"] || "0"));
    // totalIn["FORL&dot;"] += parseInt(
    //   String(movement.Packages["FORL&dot;"] || "0")
    // );
  });

  return Object.entries(totalIn)?.map(([packageName, inValue]) => ({
    packageName: t(`${packageName}`),
    packageKey: packageName,
    in: inValue,
    out: 0
  }));
};

export const ContainersTable = ({
  submitRef: submitTotalRef,
  fullWidth = false,
  hideIn = false
}: {
  submitRef: React.MutableRefObject<any>;
  fullWidth?: boolean;
  hideIn?: boolean;
}) => {
  const { selectedMovements, selectMovements } = useMovementsConfirm();
  const { t } = useLang();

  const { fields } = useViewSettings();
  const { openSnackbar } = useSnackbar();

  const [data, setData] = React.useState<PackageContainerInfo[]>([]);
  const packageOptions = fields
    .filter(({ field }) => field && field.includes("Package"))
    .map(({ field }) => (field ? field.replace("Packages.", "") : ""));

  React.useEffect(() => {
    const totalData = () => getInitTotalData(selectedMovements, fields, t);
    setData(totalData);
  }, [fields]);

  const handleAddRow = (option: string) =>
    setData(prev => [
      ...prev,
      { packageName: t(option), packageKey: option, in: 0, out: 0 }
    ]);

  const totalColumns = React.useMemo(
    () => getTotalColumns(t, handleAddRow, packageOptions, hideIn),
    []
  );

  const [totalLoading, setTotalLoading] = React.useState(false);

  const [employeeNr, setEmployeeNr] = React.useState("");
  const [comment, setComment] = React.useState("");

  //MATERIAL TABLE BULK EDIT PATCH
  //TODO: move it inside wrapper component or/and inside material table
  //hack for controlling table edit mode

  const startEditTotalRef = React.useRef<any>(null);

  const handleEditTotal = async (
    editedData: Record<
      number,
      {
        oldData: PackageContainerInfo;
        newData: PackageContainerInfo;
      }
    >
  ) => {
    try {
      setTotalLoading(true);
      const containers: PackageContainerInfo[] = data.map((packageInfo, i) => {
        if (editedData[i]) {
          return editedData[i].newData;
        } else {
          return packageInfo;
        }
      });
      const result = await registerContainer(containers, {
        employeeNr,
        comment
      });
    } catch (e: any) {
      console.error(e);
      openSnackbar(e?.message, "error");
    }
    setTotalLoading(false);
  };

  const editTotalPatch = bulkEditPatch<PackageContainerInfo>(
    submitTotalRef,
    startEditTotalRef,
    handleEditTotal
  );

  React.useEffect(() => {
    if (startEditTotalRef.current && totalLoading == false) {
      startEditTotalRef.current();
    }
  }, [totalLoading]);

  //END MATERIAL TABLE BULK EDIT PATCH
  //TODO: move it to wrapper componenet
  return (
    <Box width={{ xs: "100%", md: fullWidth ? "100%" : "60%" }}>
      <Typography variant={"h5"}>{t("createContainersReceipt")}</Typography>
      <Box display={{ xs: "block", md: "flex" }} gridGap={10}>
        <Box width={{ xs: "100%", md: "150px" }}>
          <TextField
            fullWidth
            label={t("empoyeeNr")}
            value={employeeNr}
            onChange={(event: any) => setEmployeeNr(event.target.value)}
          />
        </Box>
        <Box width={{ xs: "100%", md: "250px" }}>
          <TextField
            fullWidth
            value={comment}
            onChange={(event: any) => setComment(event.target.value)}
            label={t("comment")}
          />
        </Box>
      </Box>
      <Divider />
      <MaterialTable
        isLoading={totalLoading}
        columns={totalColumns}
        editable={editTotalPatch.editableActions}
        components={{
          ...editTotalPatch.editComponents
        }}
        data={data}
        options={{
          selection: false,
          paging: false,
          ...editTotalPatch.editableOptions
        }}
      />
    </Box>
  );
};
