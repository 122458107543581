import React from "react";
import { useLang } from "../context/lang";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Icon,
  Tooltip,
  Button,
  CircularProgress,
  Typography,
  Box,
  IconButton
} from "@material-ui/core";
import { generateTrip } from "../utils/trip";

import { ResetDialog } from "./ResetDialog";
import { DownloadPdf } from "./PdfGenerator/PdfGenerator";
import { NavPdfDownloaderMany } from "./NavPdfDownloader";
import { ITrip, Movement } from "navision-proxy-api/@types/terminal";
import { useSnackbar } from "../context/snackbar";
import { PrintLabelsPdfButton } from "./PdfGenerator/PdfLabel";
import { useMovementsConfirm } from "../context/movementsConfirm";

interface IEditPanel {
  trip: ITrip;
  updateTrip: () => void;
  handleUnapproveTrip: () => Promise<void>;
  tripTableLoading: boolean;
  selectedMovements: Movement[] | undefined;
}

export const EditPanel = ({
  trip,
  updateTrip,
  handleUnapproveTrip,
  tripTableLoading,
  selectedMovements
}: IEditPanel) =>
  //editButtonRef
  {
    const [loading, setLoading] = React.useState(false);
    const [resetOpen, setResetOpen] = React.useState(false);
    const [error, setError] = React.useState("");

    const tripsViewMode = localStorage.getItem("tripsViewMode");

    const { selectMovements } = useMovementsConfirm();

    const { t } = useLang();

    const styles = useStyles();

    const { openSnackbar } = useSnackbar();

    React.useEffect(() => {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    //submiting with enter and cmd+enter
    const handleKeyDown = async (e: any) => {
      const isThisTable =
        trip.PartialTripNr === e.target.getAttribute("tripid"); //for distinguishing two opened tables

      if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
        if (
          document.activeElement &&
          document.activeElement.tagName === "INPUT" &&
          isThisTable
        ) {
          (document.activeElement as any).blur();
          //if there is several tables opened and we have coursored table
          //than we update only it
          await handleUpdate();
        }
        e.preventDefault();
      } else if (e.key === "Escape") {
        handleToogleReset();
      }
      // else if (
      //   e.key === "Enter" &&
      //   document.activeElement &&
      //   document.activeElement.tagName === "INPUT" &&
      //   isThisTable
      // ) {
      //   const index = parseInt(document.activeElement.getAttribute("rowIndex"));
      //   document.activeElement.blur();
      //   await handleUpdateRow(index);
      //   e.preventDefault();
      // }
    };

    // const handleEdit = () => {
    //   setEditing(true);
    // };

    // const handleUpdateRow = async index => {
    //   setLoading(true);
    //   try {
    //     await updateTrip(index);
    //   } catch (error) {
    //     console.log(error);
    //     const errorMessage = t(error.response?.data.code);
    //     setError(
    //       errorMessage
    //         ? `${errorMessage} ${error.response?.data.item}`
    //         : error.response?.data.message || error.message
    //     );
    //     setLoading(false);
    //   }
    //   setLoading(false);
    // };

    const handleUpdate = async () => {
      setLoading(true);
      try {
        await updateTrip();
        setLoading(false);
        //setEditing(false);
      } catch (error: any) {
        console.log(error);
        const errorMessage = t(error.response?.data.code);
        openSnackbar(
          errorMessage
            ? `${errorMessage} ${error.response?.data.item}`
            : error.response?.data.message || error.message,
          "error"
        );
        setLoading(false);
      }
    };

    const handleToogleReset = () => {
      setResetOpen(!resetOpen);
    };

    const handleReset = async () => {
      setResetOpen(false);
      setLoading(true);
      await handleUnapproveTrip();
      setLoading(false);
      // setEditing(false);
      // setEditing(true);
    };

    return loading || tripTableLoading ? (
      <CircularProgress />
    ) : (
      <Box display="flex" alignItems="center">
        {selectedMovements?.length ? (
          <Box>
            {tripsViewMode?.includes("incoming") && (
              <Box my={1} mt={2} mr={1} display="flex" width={300} height={36}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => selectMovements(selectedMovements)}
                >
                  {t("receive")}
                </Button>
              </Box>
            )}
            <Box my={1} mt={2} mr={1} display="flex" width={300} height={36}>
              <NavPdfDownloaderMany
                shipmentIds={selectedMovements.map(
                  ({ ShipmentNr }: any) => ShipmentNr
                )}
              />
            </Box>
            <Box my={1} display="flex" width={300} height={36}>
              <DownloadPdf button trip={generateTrip(selectedMovements)} />
            </Box>{" "}
            <Box my={1} display="flex" width={300} height={36}>
              <PrintLabelsPdfButton movements={selectedMovements} />
            </Box>{" "}
          </Box>
        ) : (
          <>
            <DangerousTooltip title={t("updateTrip")}>
              <IconButton onClick={handleUpdate} className={styles.margin}>
                <Icon fontSize="large" color="primary">
                  playlist_add_check
                </Icon>
              </IconButton>
            </DangerousTooltip>
            {/* <Tooltip title={t("updateRow")}>
              <Icon
                fontSize="large"
                color="primary"
                onClick={e => {
                  e.preventDefault();
                  if (lastActiveItemIndex.current !== undefined) {
                    handleUpdateRow(lastActiveItemIndex.current);
                  }
                }}
                className={styles.margin}
              >
                done
              </Icon>
            </Tooltip> */}
            <Tooltip title={t("unApprove")}>
              <IconButton onClick={handleToogleReset} className={styles.margin}>
                <Icon fontSize="large" color="error">
                  clear
                </Icon>
              </IconButton>
            </Tooltip>
            <Box ml={2} display="flex">
              <IconButton>
                <DownloadPdf trip={trip} />
              </IconButton>
            </Box>
            {error && (
              <Typography color="error">{`${t(
                "error"
              )} (${error}).`}</Typography>
            )}
          </>
        )}
        <IconButton>
          <ResetDialog
            resetOpen={resetOpen}
            handleToogleReset={handleToogleReset}
            handleReset={handleReset}
          />
        </IconButton>
      </Box>
    );
  };

const useStyles = makeStyles(theme => ({
  dangerButton: {
    color: theme.palette.error.main
  },
  margin: {
    margin: "1rem"
  }
}));

const DangerousTooltip = withStyles(theme => {
  return {
    tooltip: {
      backgroundColor: theme.palette.error.main,
      color: "white",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      padding: "0.5rem"
    }
  };
})(Tooltip);
