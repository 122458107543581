import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  TextField,
  IconButton
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Icon from "@material-ui/core/Icon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";

import { TripTable, getFieldId } from "../containers/TripTable";
import { useStore } from "../context/store";
import moment from "moment";
import { ITrip } from "navision-proxy-api/@types/terminal";
import { Loader } from "./Loader";
import { useTripsApiActions } from "../hooks/useTripsApiActions";

export const calculateApprovedSummary = (trip: ITrip) =>
  `${trip.Lines.filter(({ touchedDate }) => touchedDate).length}/${
    trip.Lines.length
  }`;

const TripTablePanel = ({
  trip,
  index,
  tvMode,
  onOpen,
  saveTrip
}: {
  trip: ITrip;
  index: Number;
  tvMode?: boolean;
  onOpen?: () => void;
  saveTrip?: (trip: ITrip) => void;
}) => {
  const styles = useStyles();
  const { editedTrips } = useStore();
  const panelRef = React.useRef<any>();
  const { updateTrip } = useTripsApiActions();

  const [isOpen, setIsOpen] = React.useState(false);

  // /** used for showing loader when table is rendering */
  // const [pendingOpening, initiateTransition] = React.useState<boolean | null>(
  //   null
  // );

  // React.useEffect(() => {
  //   const toogleOpen = () => {
  //     if (pendingOpening !== null) {
  //       setIsOpen(pendingOpening);
  //       if (panelRef.current && pendingOpening == true) {
  //         panelRef.current.scrollIntoView(true, { behavior: "smooth" });
  //       }
  //       initiateTransition(null);
  //     }
  //   };

  //   toogleOpen();
  // }, [pendingOpening]);

  React.useLayoutEffect(() => {
    if (panelRef.current && isOpen) {
      setTimeout(() => {
        panelRef.current.scrollIntoView(true, { behavior: "smooth" });
      }, 100);
    }
  }, [isOpen]);

  const calculateUnitsQuantities = () => {
    const unitsQuantity: any = {};
    trip.Lines.map(({ Unit, Quantity, LoadedQty }: any) => {
      const newQuantity = LoadedQty.map((q: any, i: any) => {
        return q == "0" || !q ? parseInt(Quantity[i]) : parseInt(q);
      });
      Unit.forEach((u: any, i: number) => {
        unitsQuantity[u] = unitsQuantity[u]
          ? unitsQuantity[u] + newQuantity[i]
          : newQuantity[i];
      });
    });
    return Object.entries(unitsQuantity)
      .map(([key, value]) => `${key}${value}`)
      .join(", ");
  };

  const calculatePorts = () => {
    const ports: any = [];
    trip.Lines.map(({ ActualGate }) => {
      if (ActualGate && ActualGate !== "0") {
        ports.push(ActualGate);
      }
    });
    return [...new Set(ports)].join(",");
  };

  const calculateLatestUpdateTime = () => {
    return editedTrips.includes(trip.PartialTripNr)
      ? moment(
          trip.Lines.map<any>(({ touchedDate }) => touchedDate).reduce(
            (acc, v) => (v > acc ? v : acc), //find latest date
            ""
          )
        ).format("HH:mm")
      : "-";
  };

  /** Approved status is an uncontrolled value in the TripTable. We want to update it from there without having to rerender the whole Dashboard */
  const approvedSummaryRef = React.useRef<HTMLSpanElement>(null);
  const refreshApprovedSummary = (actualTrip: ITrip) => {
    if (approvedSummaryRef.current) {
      approvedSummaryRef.current.innerText =
        calculateApprovedSummary(actualTrip);
    }
  };
  React.useEffect(() => {
    refreshApprovedSummary(trip);
  }, [trip]);

  const handleOnOpen = () => {
    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const ports = calculatePorts();

  const [savingTrip, setSavingTrip] = React.useState(false);

  const handleSavePorts = async (newPort: string) => {
    if (newPort) {
      setSavingTrip(true);
      const newTrip = {
        ...trip,
        Lines: trip.Lines.map((line, index) => {
          // //force update inputs instead rerendering
          // const inputElement = document.getElementById(
          //   getFieldId(trip.PartialTripNr, index, "ActualGate")
          // ) as HTMLInputElement;
          // if (inputElement) {
          //   inputElement.value = newPort;
          // }

          return { ...line, ActualGate: newPort, isNew: false };
        })
      };

      if (updateTrip) {
        await updateTrip(newTrip, true);

        if (saveTrip) saveTrip(newTrip);
      }

      setSavingTrip(false);
    }
  };

  return (
    <Accordion
      TransitionProps={{
        unmountOnExit: false,
        mountOnEnter: true,
        enter: false
      }}
      expanded={tvMode ? false : isOpen}
      className={styles.root}
      style={{
        backgroundColor: editedTrips.includes(trip.PartialTripNr)
          ? "#D1E0D2"
          : undefined
      }}
      onChange={(_, open) => {
        setIsOpen(open);
      }}
    >
      {/* 
      // @ts-ignore */}
      <AccordionSummary
        index={index as any}
        isRow={"true"}
        ref={panelRef as any}
        expandIcon={tvMode ? null : <ExpandMoreIcon />}
      >
        <Grid
          item
          xs={12}
          container
          wrap="wrap"
          className={styles.imageContainer}
          style={{ padding: tvMode ? "1.1em 0em" : "0em" }}
        >
          <Grid
            md={2}
            xs={6}
            item
            container
            alignContent="center"
            alignItems="center"
          >
            <Icon
              fontSize={tvMode ? "large" : undefined}
              className="mdi mdi-road-variant"
              color="primary"
            />
            <Typography
              className="search-highlightable"
              variant={tvMode ? "h5" : "body1"}
              style={{ fontWeight: tvMode ? "bold" : "normal" }}
            >
              {trip.PartialTripNr}
            </Typography>
          </Grid>
          <Grid
            md={(tvMode ? "2" : "3") as any}
            xs={6}
            item
            container
            alignContent="center"
            alignItems="center"
            wrap="nowrap"
          >
            <Icon
              fontSize={tvMode ? "large" : undefined}
              className="mdi mdi-package-variant-closed"
              color="primary"
            />
            <Typography
              variant={tvMode ? "h5" : "body1"}
              style={{ fontWeight: tvMode ? "bold" : "normal" }}
            >
              {calculateUnitsQuantities()}
            </Typography>
          </Grid>
          <Grid
            md={(tvMode ? "2" : "1") as any}
            xs={6}
            item
            container
            alignContent="center"
            alignItems="center"
            wrap="nowrap"
          >
            <Icon fontSize={tvMode ? "large" : undefined} color="primary">
              check
            </Icon>
            <Typography
              variant={tvMode ? "h5" : "body1"}
              style={{ fontWeight: tvMode ? "bold" : "normal" }}
            >
              <span ref={approvedSummaryRef} />
            </Typography>
          </Grid>
          <Grid
            md={(tvMode ? "1" : "1") as any}
            xs={6}
            item
            container
            alignContent="center"
            alignItems="center"
          >
            <Icon
              fontSize={tvMode ? "large" : ("" as any)}
              className="mdi mdi-truck-trailer"
              color="primary"
            />
            <Typography
              className="search-highlightable"
              variant={tvMode ? "h5" : "body1"}
              style={{ fontWeight: tvMode ? "bold" : "normal" }}
            >
              {trip.Trailer || "-"}
            </Typography>
          </Grid>
          <Grid
            md={1}
            xs={6}
            item
            container
            alignContent="center"
            alignItems="center"
          >
            <Icon
              fontSize={tvMode ? "large" : undefined}
              className="mdi mdi-truck"
              color="primary"
            />
            <Typography
              className="search-highlightable"
              variant={tvMode ? "h5" : "body1"}
              style={{ fontWeight: tvMode ? "bold" : "normal" }}
            >
              {trip.Vehicle || "-"}
            </Typography>
          </Grid>
          <Grid
            md={2}
            xs={6}
            item
            container
            alignContent="center"
            alignItems="center"
          >
            <Icon
              fontSize={tvMode ? "large" : undefined}
              className="mdi mdi-home-export-outline"
              color="primary"
            />

            {ports ? (
              <Typography
                className="search-highlightable"
                variant={tvMode ? "h5" : "body1"}
                style={{ fontWeight: tvMode ? "bold" : "normal" }}
              >
                {ports}
              </Typography>
            ) : (
              <Box display="flex">
                <Box width="100px">
                  <TextField
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    inputProps={{
                      onKeyDown: function (e: any) {
                        if (e.key === "Enter") {
                          (document?.activeElement as any)?.blur();
                        }
                      }
                    }}
                    onBlur={e => handleSavePorts(e.target.value)}
                  />
                </Box>

                <IconButton
                  size="small"
                  onClick={() => (document?.activeElement as any)?.blur()}
                >
                  <Loader loading={savingTrip}>
                    <CheckIcon color="primary" />
                  </Loader>
                </IconButton>
              </Box>
            )}
          </Grid>
          <Grid
            md={1}
            xs={6}
            item
            container
            alignContent="center"
            alignItems="center"
          >
            <Icon
              fontSize={tvMode ? "large" : undefined}
              className="mdi mdi-clock-outline"
              color="primary"
            />
            <Typography
              variant={tvMode ? "h5" : "body1"}
              style={{ fontWeight: tvMode ? "bold" : "normal" }}
            >
              {trip.StartTime}
            </Typography>
          </Grid>
          <Grid
            md={1}
            xs={6}
            item
            container
            alignContent="center"
            alignItems="center"
          >
            <Icon
              fontSize={tvMode ? "large" : undefined}
              className="mdi mdi-clock-check-outline"
              color="primary"
            />
            <Typography
              className="search-highlightable"
              variant={tvMode ? "h5" : "body1"}
              style={{ fontWeight: tvMode ? "bold" : "normal" }}
            >
              {calculateLatestUpdateTime()}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      {!tvMode && (
        <AccordionDetails className={styles.table}>
          <Box width="100%">
            <TripTable
              trip={trip}
              key={`${trip.PartialTripNr}_${trip.Lines[0]?.ActualGate}`} //we want to use actual gate as a key in order to remount trip table when actual gate is changed from handleSavePorts
              onOpen={handleOnOpen}
              saveTrip={saveTrip}
              close={handleClose}
              refreshApprovedSummary={refreshApprovedSummary}
            />
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default React.memo(
  TripTablePanel
  //   (prevProps: any, nextProps: any) => {
  //   return prevProps.trip.PartialTripNr == nextProps.trip.PartialTripNr;
  // }
);

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiAccordionSummary-root": {
      [theme.breakpoints.down("xs")]: {
        padding: "0 18px 0 18px"
      }
    }
  },
  imageContainer: {
    "& .material-icons": {
      lineHeight: 0.7,
      marginRight: 3
    }
  },
  table: {
    "& .MuiTableCell-paddingCheckbox": {
      //width: "144px !important",
      paddingLeft: "8px"
    },
    "& .MuiPaper-root": {
      width: "100%"
    },
    "& .MuiToolbar-root": {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column"
      }
    }
  },
  editField: {
    padding: "1rem"
  }
}));
