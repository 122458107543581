import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  InputLabel,
  CircularProgress
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { isParkingApp } from "../utils/domain";

import { useAuth } from "../context/auth";
import { useLang } from "../context/lang";

import { COLORS } from "../config/colors";

export const Login = () => {
  const auth = useAuth();
  const styles = useStyles();
  const { setLang, t } = useLang();

  const [values, setValues] = React.useState({
    username: "",
    password: ""
  });

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [values]);

  const [error, setError] = React.useState({
    username: "",
    password: ""
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!values.username) {
      setError({ ...error, username: "Username can't be empty" });
      return;
    } else if (!values.password) {
      setError({ ...error, password: "Password can't be empty" });
      return;
    } else {
      if (await auth.login(values.username, values.password)) {
        setLang(localStorage.getItem("userLanguage"));
      }
    }
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <Grid
      justify="center"
      alignItems="center"
      m={200}
      direction="column"
      container
      className={styles.root}
    >
      <Grid item xs={12}>
        <Typography className={styles.login} variant="h4">
          {`${t("feedbackApp")} - ${t("login")}`}
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        {/* <Typography className={styles.main} variant="body2">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
          et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.
        </Typography> */}
      </Grid>
      <Grid item xs={12}>
        <FormControl required variant="outlined" className={styles.form}>
          <InputLabel
            className={styles.inputLabel}
            htmlFor="outlined-adornment-username"
            error={error.username}
          >
            {t("username")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-username"
            className={styles.input}
            type="text"
            value={values.username}
            onChange={handleChange("username")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl required variant="outlined" className={styles.form}>
          <InputLabel
            className={styles.inputLabel}
            htmlFor="outlined-adornment-password"
            error={error.password}
          >
            {t("password")}
          </InputLabel>
          <OutlinedInput
            className={styles.input}
            id="outlined-adornment-password"
            type="password"
            value={values.password}
            onChange={handleChange("password")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {auth.isError && (
          <Typography color="error">{auth.errorMessage}</Typography>
        )}
        {error.username && (
          <Typography color="error">{error.username}</Typography>
        )}
        {error.password && (
          <Typography color="error">{error.password}</Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={handleSubmit}
          className={styles.button}
          variant="contained"
          type="submit"
          color="secondary"
          startIcon={
            auth.isLoading ? (
              <CircularProgress color="inherit" size="1.2rem" />
            ) : (
              <Icon>lock</Icon>
            )
          }
        >
          {t("login")}
        </Button>
      </Grid>
      {/* <Grid item xs={12}>
        <button onClick={auth.logout}>press to logout</button>
      </Grid> */}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "12rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "12rem"
    }
  },
  login: {
    color: COLORS.DARK_GREEN,
    margin: 10
  },
  main: {
    color: COLORS.GREEN_BLUE,
    textAlign: "center",
    margin: 20
  },
  form: {
    margin: 20,
    "& label": {
      color: COLORS.GREEN_BLUE
    },
    "& label.Mui-focused": {
      color: COLORS.GREEN
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLORS.GREEN
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLORS.GREEN
      },
      "&:hover fieldset": {
        borderColor: COLORS.GREEN_BLUE
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.GREEN
      }
    }
  },
  input: {
    "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
      {
        "-webkit-box-shadow": "0 0 0 30px white inset !important"
      }
  },
  inputLabel: {
    backgroundColor: "white"
  },
  button: {
    backgroundColor: COLORS.RED,
    "&:hover": {
      backgroundColor: COLORS.RED_HOVER
    },
    margin: 5
  }
}));
