import React from "react";
import MaterialTable, {
  Column,
  Components,
  MaterialTableProps,
  MTable,
  MTableAction,
  MTableToolbar
} from "material-table";
import { useMovementsConfirm } from "../../../context/movementsConfirm";
import { useLang } from "../../../context/lang";
import { Movement } from "navision-proxy-api/@types/terminal";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTripsApiActions } from "../../../hooks/useTripsApiActions";
import { set } from "lodash";
import { Loader } from "../../../components/Loader";
import { MovementsTable } from "../components/MovementsTable";
import { ContainersTable } from "../components/ContainersTable";
import { printPdfLabel } from "../../../components/PdfGenerator/PdfLabel";
import { useSnackbar } from "../../../context/snackbar";
import { useTrips } from "../../../context/trips";

const useStyles = makeStyles((theme: any) => ({
  receiveMovements: {
    "& .MuiButton-containedSizeLarge": {
      margin: "10px",
      padding: "10px 20px",
      fontSize: "1.3rem",
      width: "400px",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      }
    }
  }
}));

export default function ReceiveMovements() {
  const { selectedMovements, selectMovements } = useMovementsConfirm();
  const { updateApproveTripLines } = useTripsApiActions();
  const { loadPlans } = useTrips();
  const { t } = useLang();
  const { openSnackbar } = useSnackbar();

  const toPrintRef = React.useRef<boolean>(false);

  //we keep this refs here to submit both tables at the same time from parent component
  const submitTotalRef = React.useRef<any>(null);
  const submitMainRef = React.useRef<any>(null);

  const classes = useStyles();

  const tripNr = [
    ...new Set(selectedMovements?.map(m => m.PartialTripNr))
  ].join(", ");
  const vehicle = [...new Set(selectedMovements?.map(m => m.Vehicle))].join(
    ", "
  );

  const handleReceive = () => {
    if (submitMainRef.current) submitMainRef.current();
    if (submitTotalRef.current) submitTotalRef.current();
    //triggerSubmitEdit();
  };

  const handleReceiveAndPrint = () => {
    toPrintRef.current = true;
    if (submitMainRef.current) submitMainRef.current();
    if (submitTotalRef.current) submitTotalRef.current();
    //triggerSubmitEdit();
  };

  const handleAfterSubmit = () => {
    openSnackbar(t("movementsReceived"), "success");
    if (toPrintRef.current) {
      printPdfLabel(selectedMovements, t);
      //print labels
      toPrintRef.current = false;
    }
    //close
    selectMovements([]);
    loadPlans({ ignoreCache: false });
  };

  return (
    <Box
      width={"100%"}
      maxHeight={"90vh"}
      //overflow={"auto"}
      display="flex"
      flexWrap={"wrap"}
      className={classes.receiveMovements}
    >
      <Box width={"100%"}>
        {tripNr && (
          <Box display={"flex"}>
            <Typography variant="subtitle1">
              <b>{`${t("tripNr")}:`}</b>
            </Typography>
            <Typography variant="body1">{tripNr}</Typography>
          </Box>
        )}
        {vehicle && (
          <Box display={"flex"}>
            <Typography variant="subtitle1">
              <b>{`${t("vehicle")}:`}</b>
            </Typography>
            <Typography variant="body1">{vehicle}</Typography>
          </Box>
        )}
      </Box>
      <MovementsTable
        submitRef={submitMainRef}
        afterSubmitHook={handleAfterSubmit}
      />
      <Box
        display={"flex"}
        flexDirection={{ xs: "column-reverse", md: "row" }}
        width={"100%"}
        // marginTop={{ xs: "133px", md: 0 }}
      >
        <Box
          width={{ xs: "100%", md: "40%" }}
          marginX={{ xs: 0, md: 2 }}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleReceive()}
          >
            {t("receive")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleReceiveAndPrint()}
          >
            {t("receiveAndPrintLabel")}
          </Button>
        </Box>
        <ContainersTable submitRef={submitTotalRef} />
      </Box>
    </Box>
  );
}
