import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  List,
  ListItem,
  Paper,
  Box,
  Icon,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { QrReader } from "react-qr-reader";
import { isMobile } from "react-device-detect";
import { fetchPlans, planMovementsToTrip, trackShipmnetQR } from "../../api";
import { useSnackbar } from "../../context/snackbar";
import { ITrip, Movement } from "navision-proxy-api/@types/terminal";
import { useLang } from "../../context/lang";
import { Loader } from "../../components/Loader";
import { useAuth } from "../../context/auth";
import { set } from "lodash";
import { useHistory } from "react-router";
import _ from "lodash";
import { getFormattedDateFilter } from "../../context/trips";
import { DEFAULT_DATE_FILTER } from "../../config/app";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  cameraContainer: {},
  formContainer: {
    padding: theme.spacing(2)
  },
  listContainer: {
    width: "100%",
    maxHeight: "30vh",
    overflowY: "auto",
    marginTop: theme.spacing(2)
  },
  desktopMessage: {
    marginTop: theme.spacing(4),
    textAlign: "center"
  }
}));

export const PlanByScan = () => {
  const [scannedItems, setScannedItems] = useState<Partial<Movement>[]>([]);
  const [tripNr, setTripNr] = useState("");
  const [truckNr, setTruckNr] = useState("");
  const [trailerNr, setTrailerNr] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useLang();
  const { userDepartment } = useAuth();
  const history = useHistory();
  const [responseTrip, setResponseTrip] = useState<Partial<ITrip> | null>(null);
  const [responseErrorMessage, setResponseErrorMessage] = useState<
    string | null
  >(null);
  const [department, setDepartment] = useState(userDepartment);

  const { openSnackbar } = useSnackbar();

  /** all shipemnts map */
  const [allShipmentsMap, setAllShipmentsMap] = React.useState<{
    [shipmentNr: string]: Movement;
  }>({});
  const [shipmentMapLoading, setShipmentMapLoading] = React.useState(false);

  const loadAllShipments = React.useCallback(async () => {
    setShipmentMapLoading(true);
    const plansData = await fetchPlans({
      dateFilter: getFormattedDateFilter(DEFAULT_DATE_FILTER),
      filterMode: "outgoing" // we look for shipments that starts from here
    });

    const allShipmentsMap: { [shipmentNr: string]: Movement } = {};

    plansData.Plans.forEach(plan => {
      plan.Trips.forEach(trip => {
        trip.Lines.forEach(movement => {
          allShipmentsMap[movement.ShipmentNr] = movement;
        });
      });
    });

    setAllShipmentsMap(allShipmentsMap);
    setShipmentMapLoading(false);
  }, []);

  React.useEffect(() => {
    loadAllShipments();
  }, []);

  const getDetailedShipmentInfo = (shipmentNr: string) => {
    const detailedShipemnt = allShipmentsMap[shipmentNr];
    return detailedShipemnt;
  };

  /** scanning logic */
  const handleScan = async (url: string) => {
    if (url) {
      try {
        const shipment = await trackShipmnetQR(url);

        setScannedItems(prevItems => {
          if (prevItems.find(item => item.ShipmentNr === shipment.ShipmentNr)) {
            return prevItems;
          }
          return [...prevItems, shipment];
        });
      } catch (error: any) {
        openSnackbar(`Error tracking QR code:, ${error.message}`, "error");
      }
    }
  };

  const debouncedHandleScan = React.useCallback(
    _.debounce(handleScan, 2000, { leading: true, trailing: false }),
    []
  );

  const handleSubmit = async () => {
    try {
      const movementsInfo = scannedItems
        .map(item => getDetailedShipmentInfo(String(item.ShipmentNr)))
        .filter(Boolean);
      const planTripRequest = {
        data: {
          tripNr,
          trailerNr,
          truckNr,
          movementsInfo,
          planDepartment:
            movementsInfo[0].PlanDepartment || userDepartment || ""
        }
      };
      setSubmitLoading(true);
      const response = await planMovementsToTrip(planTripRequest);
      setResponseTrip(response.trip);
      setResponseErrorMessage(response.errorMessage);

      setSubmitLoading(false);
      //   openSnackbar(
      //     "Successfully submitted" + JSON.stringify(response),
      //     "success"
      //   );
    } catch (error) {
      openSnackbar(`Submission Error:, ${error}`, "error");
    }
  };

  //   if (!isMobile) {
  //     return (
  //       <Typography className={classes.desktopMessage}>
  //         You can use it only on mobile.
  //       </Typography>
  //     );
  //   }

  const onRemoveShipment = (index: number) =>
    setScannedItems(prev => prev.filter((_, i) => i !== index));

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      marginTop={{ xs: -2, lg: 14 }}
      position={"relative"}
      zIndex={10000}
      bgcolor="white"
    >
      <Box
        width={{ xs: "100%", lg: "50%" }}
        height={{ xs: "50vh", lg: "100%" }}
      >
        <QrReader
          constraints={{ facingMode: isMobile ? "enviroment" : "user" }}
          containerStyle={{ width: "100%", height: "100%" }}
          videoStyle={{ width: "100%", height: "50vh" }}
          onResult={(result, error) => {
            if (result) {
              debouncedHandleScan(result.getText());
            }
            if (error) {
              //handleError(error);
            }
          }}
        />
      </Box>
      <Box
        position={"absolute"}
        top={5}
        right={-10}
        color="secondary"
        bgcolor={"white"}
        style={{ opacity: 0.8 }}
      >
        <Button
          variant="outlined"
          onClick={() => history.push("/")}
          style={{ paddingRight: "5px", paddingLeft: "5px", minWidth: "32px" }}
        >
          {/* {t("close")} */}
          <Icon>close</Icon>
        </Button>
      </Box>
      <Box
        width={{ xs: "100%", lg: "50%" }}
        height={{ xs: "50vh", lg: "100%" }}
        paddingRight={{ xs: 0, lg: 10 }}
      >
        {(responseTrip || responseErrorMessage) && (
          <TripCreateResponse
            trip={responseTrip}
            errorMessage={responseErrorMessage}
            onClose={() => {
              setResponseTrip(null);
              setResponseErrorMessage(null);
              setScannedItems([]);
            }}
          />
        )}
        <ShipmentsList
          shipments={scannedItems}
          loading={shipmentMapLoading}
          getDetailedShipmentInfo={getDetailedShipmentInfo}
          onRemoveShipment={onRemoveShipment}
        />
        {scannedItems.length == 0 && (
          <Typography variant="h6" align="center">
            {t("scanItemsToSubmit")}
          </Typography>
        )}
        {/* <TextField
          label="Trip Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={tripNr}
          onChange={e => setTripNr(e.target.value)}
        /> */}
        {/* <TextField
          label="Department"
          variant="outlined"
          fullWidth
          margin="normal"
          value={department}
          onChange={e => setDepartment(e.target.value)}
        /> */}
        <TextField
          label="Truck Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={truckNr}
          onChange={e => setTruckNr(e.target.value)}
        />
        <TextField
          label="Trailer Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={trailerNr}
          onChange={e => setTrailerNr(e.target.value)}
        />

        <Loader loading={submitLoading}>
          <Button
            disabled={scannedItems.length === 0}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            {t("submit")}
          </Button>
        </Loader>
      </Box>
    </Box>
  );
};

const TripCreateResponse = ({
  trip,
  errorMessage,
  onClose
}: {
  trip: Partial<ITrip> | null;
  errorMessage: string | null;
  onClose: () => void;
}) => {
  const { t } = useLang();
  return (
    <Box m={4} width={{ xs: "100%", md: "50%" }}>
      <Typography variant="h4">{t("tripCreated")}</Typography>
      <Typography variant="body1">
        {JSON.stringify(trip || {}, null, 2)}
      </Typography>
      {errorMessage && (
        <Box mt={2}>
          <Typography variant="h5">{t("error")}</Typography>
          <Typography variant="body1">{errorMessage}</Typography>
        </Box>
      )}
      <Button variant="contained" color="primary" onClick={onClose}>
        {t("next")}
      </Button>
    </Box>
  );
};

const ShipmentsList = ({
  getDetailedShipmentInfo,
  loading,
  shipments,
  onRemoveShipment
}: {
  shipments: Partial<Movement>[];
  loading: boolean;
  getDetailedShipmentInfo: (shipmentNr: string) => Movement | undefined;
  onRemoveShipment: (index: number) => void;
}) => {
  const classes = useStyles();

  return (
    <List className={classes.listContainer}>
      {shipments.map((item, index) => (
        <ListItem key={index}>
          {item.ShipmentNr}
          <IconButton size="medium" onClick={() => onRemoveShipment(index)}>
            <Loader loading={loading}>
              <DetailedShipment
                shipment={getDetailedShipmentInfo(
                  String(item.ShipmentNr) || ""
                )}
              />
            </Loader>
            <Icon fontSize="large">clear</Icon>
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
};

const DetailedShipment = ({ shipment }: { shipment: Movement | undefined }) => {
  const { t } = useLang();
  if (!shipment) {
    return (
      <Typography color="error">
        {t("Shipment not found for this date,plans,start addresses")}
      </Typography>
    );
  }

  return (
    <Box display="flex">
      <Typography>{shipment.AddresseeName}</Typography>{" "}
      <Box ml={2}>
        <Typography>{shipment.Unit?.join(",")}:</Typography>
      </Box>
      <Typography>{shipment.LoadedQty?.join(",")}</Typography>
    </Box>
  );
};
