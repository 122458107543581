import React from "react";
import { Components, MaterialTableProps, MTableToolbar } from "material-table";
import { Box } from "@material-ui/core";

//material table hack to keep table in edit mode
export function bulkEditPatch<DataType extends object>(
  submitRef: any,
  startEditRef: any,
  onSubmit: (
    editedData: Record<
      number,
      {
        oldData: DataType;
        newData: DataType;
      }
    >
  ) => void
): {
  editComponents: Components;
  editableActions: MaterialTableProps<DataType>["editable"];
  editableOptions: MaterialTableProps<DataType>["options"];
  // editAllAction: () => void;
  //submit: () => void;
} {
  //let editAllAction: () => void;
  //let saveAllAction: () => void;

  const editComponents: Components = {
    // Action: props => {
    //   console.log("rendering action");
    //   if (props.action.tooltip === "Edit All") {
    //     //kinda hack for always keeping table in edit mode
    //     const EditButton = () => {
    //       React.useLayoutEffect(() => {
    //         console.log("cli9cking edit all");
    //         console.log(
    //           tableRef.current,
    //           !tableRef.current["dataManager"]["bulkEditOpen"],
    //           editAllAction
    //         );
    //         if (
    //           tableRef.current &&
    //           !tableRef.current["dataManager"]["bulkEditOpen"] &&
    //           editAllAction
    //           // !adding &&
    //           // !editing
    //         ) {
    //           console.log("clicking!!");
    //           editAllAction();
    //         }
    //       }, []);
    //       return null;
    //     };
    //     return <EditButton />;
    //   }
    //   return <MTableAction {...props} />;
    // },
    Toolbar: (
      props //we need to render for add actions in toolbar but not display it
    ) => {
      // addAction = props.actions.find(
      //   ({ tooltip }) => tooltip === "Add"
      // ).onClick;

      startEditRef.current = props.actions.find(
        ({ tooltip }: { tooltip: string }) => tooltip === "Edit All"
      )?.onClick;
      submitRef.current = props.actions.find(
        ({ tooltip }: { tooltip: string }) => tooltip === "Save all changes"
      )?.onClick;
      // discardAllAction = props.actions.find(
      //   ({ tooltip }) => tooltip === "Discard all changes"
      // ).onClick;
      return (
        <Box display={"none"}>
          <MTableToolbar {...props} />
        </Box>
      );
    }
  } as Components;
  const editableActions = {
    onBulkUpdate: async (
      editedData: Record<
        number,
        {
          oldData: DataType;
          newData: DataType;
        }
      >
    ) => {
      onSubmit(editedData);
    }
  };
  return {
    editComponents,
    editableActions,
    editableOptions: { actionsColumnIndex: -2, toolbar: true }
  };
}
